import { isMobileView } from '@/shared/utils/dom';

import { setCart } from './cart';

let isMobile = false;

const scrollUpToRemoveEmptySpace = () => {
  const submenus = document.querySelectorAll('.submenu');
  submenus.forEach((el) => {
    el.scroll({
      top: 0,
      behavior: 'smooth'
    });
  });
};

const setMenus = () => {
  // Offcanvas Menu Toggle
  const toggleMenu = document.querySelectorAll('.hb-toggle-menu');
  const navigationElm = document.querySelector('.hb-navigation');
  const hamburgerElm = document.querySelector('.hb-hamburger');
  let isExpanded = false;
  const body = document.querySelector('body');

  toggleMenu.forEach((btn) => {
    btn.addEventListener('click', () => {
      isExpanded = !isExpanded;
      hamburgerElm.setAttribute('aria-expanded', isExpanded);
      navigationElm.classList.toggle('offcanvas--open');
      body.classList.toggle('menu-open');
    });
  });

  const hasSubmenu = document.querySelectorAll('[data-has-submenu]');
  hasSubmenu.forEach((item) => {
    item.addEventListener('click', (e) => {
      if (!isMobile) {
        return;
      }
      e.preventDefault();
      scrollUpToRemoveEmptySpace();

      item
        .closest('li')
        .querySelectorAll('.submenu')[0]
        .classList.toggle('submenu--active');
    });
  });

  const closeSubmenu = document.querySelectorAll('[data-close-submenu]');
  closeSubmenu.forEach((item) => {
    item.addEventListener('click', () => {
      item.closest('.submenu').classList.remove('submenu--active');
    });
  });

  // Tabs in Yarn-mobile menu
  const menuTabs = document.querySelectorAll('[data-menu-tab]');
  menuTabs.forEach((tab) => {
    tab.addEventListener('click', (e) => {
      const menuTabContent = document.querySelectorAll(
        '[data-menu-tab-content]'
      );
      const activeContents = document.querySelectorAll(
        `[name=${tab.dataset.menuTab}]`
      );

      // select the active tab
      menuTabs.forEach((item) => {
        item.classList.remove('tab--active');
      });
      e.target.classList.add('tab--active');

      // remove the previous active content
      menuTabContent.forEach((item) => {
        item.classList.remove('block');
        item.classList.add('hidden');
      });

      // select the new active content
      activeContents.forEach((activeContent) => {
        activeContent.classList.remove('hidden');
        activeContent.classList.add('block');
      });
    });
  });
};

const setIsMobile = () => {
  isMobile = isMobileView();
};

window.addEventListener('resize', setIsMobile);

document.addEventListener('DOMContentLoaded', () => {
  setCart();
  setMenus();
  setIsMobile();
});
